import React, { useState, useEffect, useRef } from "react"
import { TxResult, useWallet } from "@terra-money/wallet-provider"
import { useNetwork, useContract, useAddress, useConnectModal } from "hooks"
import { Coin, Coins, CreateTxOptions, Fee, MsgSend, SignerInfo, int, } from "@terra-money/terra.js"
import { useLCDClient } from "layouts/WalletConnectProvider"
import { MsgInstantiateContract, MsgExecuteContract } from "@terra-money/terra.js"
import useGasPrice from "rest/useGasPrice"

import 'orderbook.css'
import { ULUNA, cwLUNA, BASE, FROG, BINDER_ADDR, PAIRS } from "./coins_tokens"
import styled from "styled-components"
import Container from "components/Container"
import Result from "forms/Result"
import Peer2PeerDisclaimer from "components/CreateContractNotice"
import { useModal } from "components/Modal"

import BASE_ICON from 'images/Token/LBUNC_128.png'

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-left: 20px; 
  padding-right: 20px;
  `
function OrderBook() {
 
  const wallet = useWallet()
  const walletAddress = useAddress()
  const connectModal = useConnectModal()
  const { terra } = useLCDClient()
  const { gasPrice } = useGasPrice("uluna")


  const [result, setResult] = useState()
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const [userAddress, setUserAddress] = useState('');
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [inputFlag, setInputFlag] = useState('');
  const [connected, setConnected] = useState(false);

  const [tokenChainPrice, setTokenChainPrice] = useState(0); // State to store the fetched spot price
  const [luncPrice, setLuncPrice] = useState(0)
  const [orderType, setOrderType] = useState('buy');
  const [amount, setAmount] = useState('');
  const [tableData, setTableData] = useState([])
  const [ownerTableData, setOwnerTableData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [contractAddress, setContractAddress] = useState();
  let [price, setPrice] = useState("")
  let [quantity, setQuantity] = useState("")
  const [tax, setTax] = useState("")
  const [total, setTotal] = useState("")
  const [rate, setRate] = useState("")

  let [orderPrice, setOrderPrice] = useState(0)
  let [orderSize, setOrderSize] = useState(0)
  let [orderFee, setOrderFee] = useState(0)
  let [orderTotal, setOrderTotal] = useState(0)
  let [orderId, setOrderId] = useState(0)
  let [orderContract, setOrderContract] = useState("")

  const [selectedOption, setSelectedOption] = useState(() => {
    const savedOption = localStorage.getItem("selectedOption");
    return savedOption && PAIRS[savedOption] ? savedOption : Object.keys(PAIRS)[0];
  });

  const [market, setMarket] = useState(PAIRS[selectedOption].market);
  const [bIcon, setBIcon] = useState(PAIRS[selectedOption].b_icon);
  const [bSymbol, setBSymbol] = useState(PAIRS[selectedOption].b_symbol);
  const [bAddress, setBAddress] = useState(PAIRS[selectedOption].b_address);
  const [qSymbol, setQSymbol] = useState(PAIRS[selectedOption].q_symbol);
  const [qAddress, setQAddress] = useState(PAIRS[selectedOption].q_address);
  const [tokenType, setTokenType] = useState(PAIRS[selectedOption].tokenType);
  const [tableKey, setTableKey] = useState(PAIRS[selectedOption].tableKey);


  const [myBuyOrders, setMyBuyOrders] = useState([]);
  const [mySellOrders, setMySellOrders] = useState([]);

  const [buyOrdersList, setBuyOrdersList] = useState([]);
  const [sellOrdersList, setSellOrdersList] = useState([]);
  const [spreadPercentage, setSpreadPercentage] = useState(0);

  const peer2PeerDisclaimerRef = useRef(null);
  const [isOwnerEntryUndefined, setIsOwnerEntryUndefined] = useState(false);

  const [isBuyDisabled, setIsBuyDisabled] = useState(false);
  const [isSellDisabled, setIsSellDisabled] = useState(false);

  const handleOptionChange = (event) => {
    const selectedKey = event.target.value;
    setSelectedOption(selectedKey);
  };
  

  const prevWalletAddressRef = useRef();
  
  useEffect(() => {
    if (prevWalletAddressRef.current && prevWalletAddressRef.current !== walletAddress) {
      window.location.reload();
    }
    prevWalletAddressRef.current = walletAddress;
  }, [walletAddress]);
   

  useEffect(() => {
    fetchLuncPrice();
  }, []);

  useEffect(() => {
    fetchTokenPrice(bAddress, tokenType);
  }, [market]);

  useEffect(() => {
    fetchOwnerTable();
  }, [market]);

  useEffect(() => {
    fetchBinderData();
  }, [market]);

 
  useEffect(() => {

    if (ownerTableData && ownerTableData.length > 0) {
      const ownerEntry = ownerTableData.find(entry => entry.owner === walletAddress);

      if (!ownerEntry) {
        setIsOwnerEntryUndefined(true);
      } else {
        setIsOwnerEntryUndefined(false);
      }
    }
  
  }, [ ownerTableData]);

  useEffect(() => {
    const selectedPair = PAIRS[selectedOption];
    if (selectedPair) {
      setMarket(selectedPair.market);
      setBIcon(selectedPair.b_icon);
      setBSymbol(selectedPair.b_symbol);
      setBAddress(selectedPair.b_address);
      setQSymbol(selectedPair.q_symbol);
      setQAddress(selectedPair.q_address);
      setTokenType(selectedPair.tokenType);
      setTableKey(selectedPair.tableKey);
  
      // Set order fee based on the base symbol
      if (selectedPair.b_symbol === "BASE") {
        setOrderFee(0);
      } else {
        setOrderFee(0.1);
      }
  
      // Save the selected option to localStorage
      localStorage.setItem("selectedOption", selectedOption);
    } else {
      console.warn(`Selected option "${selectedOption}" not found in PAIRS.`);
    }
  }, [selectedOption]);



  const instantiateNewContract = async () => {
    if (walletAddress) {
      try {
        await instantiateContract(walletAddress);
      } catch (error) {
        console.error("Error instantiating contract:", error);
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  };


  const handleAgreement = async () => {
    if (walletAddress) {
      await checkAndInstantiateContract(walletAddress);
      await fetchOwnerTable();
      await fetchBinderData();
    }
  };

  const checkAndInstantiateContract = async (walletAddress) => {
    peer2PeerDisclaimerRef.current.openModal();
    const newContractAddress = await instantiateContract(walletAddress);
  };

  useEffect(() => {
    filterOrders();
  }, [walletAddress, tableData, market]);

  const filterOrders = () => {
    if (walletAddress && tableData) {
      const buyOrders = tableData.filter(order => order.action === "buy" && 
                        order.owner === walletAddress && order.cw20_address === bAddress);
      const sellOrders = tableData.filter(order => order.action === "sell" && 
                        order.owner === walletAddress && order.cw20_address === bAddress);

      // Set these filtered orders in state
      setMyBuyOrders(buyOrders);
      setMySellOrders(sellOrders);
    }
  };

  // Use useEffect to populate price and quantity when wallet addr is available
  useEffect(() => {
    if (walletAddress && !isLoading) {
      const initialPrice = getMyPrice();
      const initialQuantity = getMyQuantity();
      setPrice(initialPrice);
      setQuantity(initialQuantity);
    }
  }, [walletAddress, isLoading, market]);


  useEffect(() => {
      const sellOrders = tableData
        .filter(data => data.action === "sell" && data.cw20_address === bAddress)
        .sort((a, b) => a.price - b.price);
  
      const buyOrders = tableData
        .filter(data => data.action === "buy" && data.cw20_address === bAddress)
        .sort((a, b) => b.price - a.price);
  
      const lowestAsk = sellOrders.length > 0 ? sellOrders[0].price : null;
      const highestBid = buyOrders.length > 0 ? buyOrders[0].price : null;
  
      let spreadPercentage = 0;

      if (lowestAsk !== null && highestBid !== null && highestBid !== 0) {
        const spread = lowestAsk - highestBid;
        spreadPercentage = (((spread) / highestBid) * 100).toFixed(3);
      }
  
      setSellOrdersList(sellOrders);
      setBuyOrdersList(buyOrders);
      setSpreadPercentage(spreadPercentage);

  }, [tableData, bAddress]);


  const fetchLuncPrice = () => {
    const url3 =
      "https://graphsv2.coinpaprika.com/currency/data/luna-terra/24h/?quote=usd"
    fetch(url3)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const apiCoinPaprikaLunc = JSON.parse(text)
        const priceArray = apiCoinPaprikaLunc[0].price;
        const latestData = priceArray[priceArray.length - 1];
        const [timestamp, price] = latestData;
        setLuncPrice(Number(price))
      })
      .catch((error) => console.error("Error fetching LUNC price:", error));
  };

  const fetchTokenPrice = async (bAddress, tokenType) => {
    if (tokenType === "tbc") {
      const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${bAddress}/smart/eyJjdXJ2ZV9pbmZvIjp7fX0=`;
      
      try {
        const response = await fetch(url);
        const data = await response.json();
        let fetchedPrice = Number(data.data.spot_price) / 1000000;
        fetchedPrice = fetchedPrice * 1.058734; // Account for taxes to get to buy price
        setTokenChainPrice(fetchedPrice);
      } catch (error) {
        console.error("Error fetching spot price:", error);
      }
    } else if (tokenType === "xyk") {
      // Retrieve the corresponding pair from PAIRS using bAddress
      const pair = Object.values(PAIRS).find(pair => pair.b_address === bAddress);
      
      if (!pair) {
        console.warn(`No pair found for bAddress: ${bAddress}`);
        return;
      }
  
      const tokenPool = pair.tokenPool;
      if (!tokenPool) {
        console.warn(`No tokenPool defined for pair with bAddress: ${bAddress}`);
        return;
      }
  
      const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${tokenPool}/smart/eyJwb29sIjp7fX0=`;
      
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        // Extract assets
        const assets = data.data.assets;
        let ulunaAmount = 0;
        let cw20Amount = 0;
  
        assets.forEach(asset => {
          if (asset.info.native_token && asset.info.native_token.denom === "uluna") {
            ulunaAmount = Number(asset.amount);
          } else if (asset.info.token && asset.info.token.contract_addr === bAddress) {
            cw20Amount = Number(asset.amount);
          }
        });
  
        if (cw20Amount === 0) {
          console.error("CW20 amount is zero, cannot calculate price.");
          return;
        }
  
        const price = ulunaAmount / cw20Amount;
    
        setTokenChainPrice(price); // or setTokenChainPrice(adjustedPrice);
      } catch (error) {
        console.error("Error fetching XYK price:", error);
      }
    } else {
      console.warn(`Unsupported token type: ${tokenType}`);
    }
  };

  const fetchBinderData = async () => {
    setIsLoading(true);
  
    try {
      // {"get_binder":{}} is eyJnZXRfYmluZGVyIjp7fX0=
      const binderResponse = await fetch(
        `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${BINDER_ADDR}/smart/eyJnZXRfYmluZGVyIjp7fX0=`
      );
      const binderData = await binderResponse.json();
  
      if (binderData && binderData.data && binderData.data.binder.tables) {
        const tables = binderData.data.binder.tables;
        const validResults = [];
  
        for (const tableKey in tables) {
          const table = tables[tableKey];
          if (table.rows && table.rows.length > 0) {
            table.rows.forEach(row => {
  
              const price = parseFloat(row.price) / 1000000;
              const size = parseFloat(row.size) / 1000000;
              const fees_tax = parseFloat(row.fees_tax) / 1000000;
              const total = parseFloat(row.total) / 1000000;
              const rate = total / size;
  
              validResults.push({
                cw20_address: tableKey.split('-')[0],
                ledger_id: row.ledger_id,
                order_id: row.order_id,
                owner: row.owner,
                contract: row.contract,
                action: row.action,
                price,
                size,
                fees_tax,
                total,
                rate,
              });
            });
          }
        }
  
        setTableData(validResults);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  
  const fetchOwnerTable = async () => {
    setIsLoading(true);
  
    try {
      // {"get_owner_table":{}} is eyJnZXRfb3duZXJfdGFibGUiOnt9fQ==
      const ownerTableResponse = await fetch(
        `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/${BINDER_ADDR}/smart/eyJnZXRfb3duZXJfdGFibGUiOnt9fQ==`
      );
      const ownerTableData = await ownerTableResponse.json();
  
      if (ownerTableData && ownerTableData.data && ownerTableData.data.rows) {
        const rows = ownerTableData.data.rows;
        const ownerMapping = rows.map(row => ({
          contract: row.contract,
          owner: row.owner,
        }));
  
        setOwnerTableData(ownerMapping); // Update the state with the fetched data
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching owner table data:", error);
      setIsLoading(false);
    }
  };
  

  const getMyPrice = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.price;
      }
    }
    return "No price found for the current wallet";
  };

  const getMyQuantity = () => {
    if (walletAddress) {
      const currentRow = tableData.find((row) => row.owner === walletAddress);
      if (currentRow) {
        return currentRow.quantity;
      }
    }
    return "No quantity found for the current wallet";
  };

  const populateAsks = () => {
    // Sort the tableData array based on the cwLUNC/BASE ratio (rate)
    const sortedData = tableData.sort((a, b) => a.rate - b.rate);
    const filteredData = sortedData.filter((row) => row.quantity > 1);

    return filteredData.map((row, index) => (
      <div key={`ask-${index}`} className="row">  
        <div className="price">{addCommasToNumber(row.price.toFixed(6))}</div>
        <div className="size order-size">{addCommasToNumber(row.quantity.toFixed(6))}</div>
      </div>
    ));
  };

  const handleOrderBookClick = (row, action) => {

    // Update state variables
    setOrderPrice(row.price.toFixed(6));
    setOrderSize(row.size.toFixed(6));
    setOrderTotal((row.total).toFixed(6));
    setOrderId(row.order_id);
    setOrderContract(row.contract);

    if (bSymbol == "BASE"){
      setOrderFee(Number(0));
    } else {
      setOrderFee(row.fees_tax);
    }

    if (action == "ask") {
      setIsBuyDisabled(false);
      setIsSellDisabled(true);
    } else if (action == "bid") {
      setIsBuyDisabled(true);
      setIsSellDisabled(false);
    }
  };

  function addCommasToNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const findDetailsByPrice = (price) => {
    const result = tableData.find(item => item.price === price);
    if (result) {
      return {
        contract: result.contract,
        tax: result.fees_tax,
        total: result.total
      };
    }
    return "No details found with specified price";
  };

  const handleBuyClick = async (row, actionType) => {
    console.log("Buy button clicked");
    const executeMsg = {};
  
    if (walletAddress) {
      try {
        let msgs = [];
        let matchingOrder = null;
  
        // Get the selected market details
        const selectedPair = PAIRS[selectedOption];
  
        // Calculate orderTotal if not provided
        let calculatedOrderTotal = Number(orderTotal);
        if (!calculatedOrderTotal || calculatedOrderTotal === 0) {
          calculatedOrderTotal = Number(orderPrice) * Number(orderSize);
        }
  
        // Filter the table data corresponding to the selected market's quote token
        const filteredTableData = tableData.filter(data => data.cw20_address === bAddress && data.action === "sell");
  
      // Check for a matching sell order
      for (const tableRow of filteredTableData) {
        if (
          tableRow.action === "sell" && // Ensure the order is a sell order
          tableRow.price === Number(orderPrice) &&
          tableRow.size >= Number(orderSize)
        ) {
          matchingOrder = tableRow;
          break;
        }
      }
  
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);
  
        // Find the contract address for the wallet owner in the ownerTableData
        const ownerEntry = ownerTableData.find((entry) => entry.owner === walletAddress);
        if (!ownerEntry) {
          const error = new Error("Create a contract for this wallet address first");
          setResult(error);
          setIsResultModalOpen(true);
          return; // Early return to avoid further execution
        }
  
      // ----------------- Determine Fee Receiving Contract -----------------
      const feeReceivingContract = matchingOrder ? matchingOrder.contract : ownerEntry.contract;


        // ----------------- Consolidated Fee Code -----------------
        // Pay Fee message
        const payFeeMsg = {
          PayFee: {
            denom: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m", // The fee contract address
          },
        };
        const encodedPayFeeMsg = btoa(JSON.stringify(payFeeMsg));
  
        // Execute message for paying the fee
        const feeExecuteMsg = {
          send: {
            contract: feeReceivingContract, // Fee receiving contract
            amount: (Math.ceil(0.1 * 1000000)).toString(),
            msg: encodedPayFeeMsg,
          },
        };
  
        // Fee transaction
        const feeMsg = new MsgExecuteContract(
          walletAddress,
          "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m", // The contract address of the fee token
          feeExecuteMsg,
          {}
        );
        
        // Initialize msgs array with the feeMsg
        if (bSymbol == "BASE"){
          // Fee not required if buying BASE
          msgs = [];
        } else {
          msgs = [feeMsg];
        }
        let memo = "";

        // ----------------- Conditional Blocks -----------------
        if (matchingOrder) {
          // Match found - prepare execute message for filling the ask (buying from the ask order)
  
          // Make Fill message and encode
          const fillMsg = {
            FillAskCw20ToCw20: {
              order_id: matchingOrder.order_id,
              price: (Math.ceil(Number(orderPrice) * 1000000)).toString(),
            },
          };
  
          const encodedMsg = btoa(JSON.stringify(fillMsg));
  
          // Message to client's personal contract
          executeMsg.send = {
            contract: matchingOrder.contract,
            amount: (Math.floor(calculatedOrderTotal * 1000000)).toString(), // Total amount in quote tokens
            msg: encodedMsg,
          };
  
          memo = "P2P_Fill_Ask";
  
          // Route through CW20 quote token contract
          const askMsgTransaction = new MsgExecuteContract(
            walletAddress,
            qAddress, // The contract address of the CW20 quote token
            executeMsg,
            {}
          );
  
          // Append the askMsgTransaction to msgs
          msgs.push(askMsgTransaction);
        } else {
          // No match found - prepare execute message for a new bid order (sending quote tokens to create a bid)
  
          // Make Bid message and encode
          const bidMsg = {
            EnterBidCw20ToCw20: {
              price: (Math.ceil(Number(orderPrice) * 1000000)).toString(),
              denom_to: bAddress,
            },
          };
          const encodedBidMsg = btoa(JSON.stringify(bidMsg));
  
          // Execute message for the bid
          executeMsg.send = {
            contract: ownerEntry.contract, // Use the contract address from the ownerTableData
            amount: (Math.floor(calculatedOrderTotal * 1000000)).toString(), // Total amount in quote tokens
            msg: encodedBidMsg,
          };
  
          memo = "P2P_Enter_Bid";
  
          // Bid transaction
          const bidMsgTransaction = new MsgExecuteContract(
            walletAddress,
            qAddress, // The contract address of the CW20 quote token
            executeMsg,
            {}
          );
  
          // Append the bidMsgTransaction to msgs
          msgs.push(bidMsgTransaction);
        }
  
        // ----------------- Transaction Options -----------------
        const txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}uluna`,
          gasAdjustment: 2.2,
        };
  
        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );
  
        terra.tx
          .create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {
            const fee = signMsg.auth_info.fee.amount.add(
              new Coin("uluna", simulationFeeResult.amount._coins.uluna.amount)
            );
  
            const biggerFee = new Coin("uluna", Math.floor(Number(fee.get("uluna")?.amount) * 1.0));
  
            txOptions.fee = new Fee(simulationFeeResult.gas_limit, new Coins([biggerFee]));
            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        error.response.data.message = transformErrMessage(error.response.data.message);
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  
    if (orderSize > 0 && orderPrice > 0) {
      console.log(
        `Submitting buy order for ${orderSize} ${bSymbol} at price ${orderPrice} cwLUNC`
      );
      // Further API call to submit this data or internal method to handle the trade
    } else {
      console.log("Invalid order size or price");
    }
  };
  
  
  const handleSellClick = async (row, actionType) => {
    console.log("Sell button clicked");
    const executeMsg = {};
  
    if (walletAddress) {
      try {
        let msgs = [];
        let matchingOrder = null;
  
        // Get the selected market details
        const selectedPair = PAIRS[selectedOption];
  
        // Filter the table data corresponding to the selected market's table key
        const filteredTableData = tableData.filter(data => data.cw20_address === bAddress && data.action === "buy");

        // Check for a matching buy order
        for (const tableRow of filteredTableData) {
          if (tableRow.price === Number(orderPrice) && tableRow.size >= Number(orderSize)) {
            matchingOrder = tableRow;
            break;
          }
        }
  
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);
  
        // Find the contract address for the wallet owner in the ownerTableData
        const ownerEntry = ownerTableData.find(entry => entry.owner === walletAddress);
        if (!ownerEntry) {
          const error = new Error("Create a contract for this wallet address first");
          setResult(error);
          setIsResultModalOpen(true);
          return; // Early return to avoid further execution
        }
  
      // ----------------- Determine Fee Receiving Contract -----------------
      const feeReceivingContract = matchingOrder ? matchingOrder.contract : ownerEntry.contract;

        // ----------------- Consolidated Fee Code -----------------
        // Pay Fee message
        const payFeeMsg = {
          PayFee: {
            denom: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m"  // The fee contract address
          }
        };
        const encodedPayFeeMsg = btoa(JSON.stringify(payFeeMsg));
  
        // Execute message for paying the fee
        const feeExecuteMsg = {
          send: {
            contract: feeReceivingContract, // Fee receiving contract
            amount: (Math.ceil(0.1 * 1000000)).toString(),
            msg: encodedPayFeeMsg,
          },
        };
  
        // Fee transaction
        const feeMsg = new MsgExecuteContract(
          walletAddress,
          "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m", // The contract address of the fee token
          feeExecuteMsg,
          {}
        );
  
        // Initialize msgs array with the feeMsg
        msgs = [feeMsg];
        let memo = "";

        //make sure the FE is updated
        setOrderFee(0.1);
  
        // ----------------- Conditional Blocks -----------------
        if (matchingOrder) {
          // Match found - prepare execute message for filling the bid (send CW20 tokens)
  
          // Make Fill message and encode
          const fillMsg = {
            FillBidCw20ToCw20: {
              order_id: matchingOrder.order_id,
              price: (Math.ceil(orderPrice * 1000000)).toString(),
            },
          };
  
          const encodedMsg = btoa(JSON.stringify(fillMsg));
  
          // Message to client's personal contract
          executeMsg.send = {
            contract: matchingOrder.contract,
            amount: (Math.floor(orderSize * 1000000)).toString(),
            msg: encodedMsg,
          };
  
          memo = "P2P_Fill_Bid";
  
          // Route through CW20 token contract
          const bidMsgTransaction = new MsgExecuteContract(
            walletAddress,
            bAddress, // The contract address of the CW20 token
            executeMsg,
            {}
          );
  
          // Append the bidMsgTransaction to msgs
          msgs.push(bidMsgTransaction);
  
        } else {
          // No match found - prepare execute message for a new ask order (send CW20 tokens)
  
          // Make Ask message and encode
          const askMsg = {
            EnterAskCw20ToCw20: {
              price: (Math.ceil(orderPrice * 1000000)).toString(),
              denom_to: qAddress
            },
          };
          const encodedAskMsg = btoa(JSON.stringify(askMsg));
  
          // Execute message for the ask
          const askExecuteMsg = {
            send: {
              contract: ownerEntry.contract, // Use the contract address from the ownerTableData
              amount: (Math.floor(orderSize * 1000000)).toString(),
              msg: encodedAskMsg,
            },
          };
  
          memo = "P2P_Enter_Ask";
  
          // Ask transaction
          const askMsgTransaction = new MsgExecuteContract(
            walletAddress,
            bAddress, // The contract address of the CW20 token
            askExecuteMsg,
            {}
          );
  
          // Append the askMsgTransaction to msgs
          msgs.push(askMsgTransaction);
        }
  
        // ----------------- Transaction Options -----------------
        const txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: 2.2,
        };
  
        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );
  
        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {
            const fee = signMsg.auth_info.fee.amount.add(
              new Coin("uluna", simulationFeeResult.amount._coins.uluna.amount)
            );
  
            const biggerFee = new Coin(
              "uluna",
              Math.floor(Number(fee.get("uluna")?.amount) * 1.0)
            );
  
            txOptions.fee = new Fee(simulationFeeResult.gas_limit, new Coins([biggerFee]));
            //txOptions.fee = new Fee(1000000, new Coins([biggerFee]));
            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        error.response.data.message = transformErrMessage(error.response.data.message);
        setResult(error);
        setIsResultModalOpen(true);
      }
    } else {
      connectModal.open();
    }
  
    if (orderSize > 0 && orderPrice > 0) {
      console.log(`Submitting sell order for ${orderSize} ${bSymbol} at price ${orderPrice} cwLUNC`);
      // Further API call to submit this data or internal method to handle the trade
    } else {
      console.log("Invalid order size or price");
    }
  };

  const handleCancelClick = async (order_id, contract_address) => {
    const executeMsg = {};
  
    if (walletAddress) {
      try {
        let msgs = [];

        // ----------------- Consolidated Fee Code -----------------
        // Pay Fee message
        const payFeeMsg = {
          PayFee: {
            denom: "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m"  // The fee contract address
          }
        };
        const encodedPayFeeMsg = btoa(JSON.stringify(payFeeMsg));
  
        // Execute message for paying the fee
        const feeExecuteMsg = {
          send: {
            contract: contract_address, // Fee receiving contract
            amount: (Math.ceil(0.1 * 1000000)).toString(),
            msg: encodedPayFeeMsg,
          },
        };
  
        // Fee transaction
        const feeMsg = new MsgExecuteContract(
          walletAddress,
          "terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m", // The contract address of the fee token
          feeExecuteMsg,
          {}
        );
  
        // Initialize msgs array with the feeMsg
        msgs = [feeMsg];

        executeMsg.cancel_order = { order_id: Number(order_id), on_ledger: true};
        let memo = "Cancel Order";
        // Get account info to retrieve sequence number and public key
        const accountInfo = await terra.auth.accountInfo(walletAddress);
  
        const cancelMsg = new MsgExecuteContract(
          walletAddress,
          contract_address,
          executeMsg,
        );
        
                  // Append the askMsgTransaction to msgs
                  msgs.push(cancelMsg);

        const txOptions = {
          msgs,
          memo,
          gasPrices: `${gasPrice}${"uluna"}`,
          gasAdjustment: 2.2,
        };
  
        // Simulate the transaction to get estimated fees
        const simulationFeeResult = await terra.tx.estimateFee(
          [
            {
              sequenceNumber: accountInfo.getSequenceNumber(),
              publicKey: accountInfo.getPublicKey(),
            },
          ],
          txOptions
        );

        const estimatedFeeAmount = simulationFeeResult.amount;
        const estimatedGasAmount = (simulationFeeResult.gas_limit * 1);

        const fee = new Fee(estimatedGasAmount, estimatedFeeAmount);
    
        terra.tx.create([{ address: walletAddress }], txOptions)
          .then((signMsg) => {

            //txOptions.fee = fee;

            return wallet.post(txOptions, walletAddress);
          })
          .then((extensionResult) => {
            if (extensionResult) {
              setResult(extensionResult);
              setIsResultModalOpen(true);
            }
          })
          .catch((error) => {
            console.error(error);
            const errorMessage = error.message || "An error occurred during the transaction";
            setResult(error);
            setIsResultModalOpen(true);
          });
      } catch (error) {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
      }
    } else {
      connectModal.open();
    }
  };

  const instantiateContract = async (walletAddress) => {
    const codeId = 9409; // Replace with your actual code ID
    const instantiateMsg = {
      manager: "default",
      client: walletAddress,
      ledger: "default",
    };
    const initMsg = new MsgInstantiateContract(
      walletAddress, // Sender
      "terra1vgdez00kxrdnj5xkzey3nvzxj8rjgw0rspxgpw", // Admin
      codeId,
      instantiateMsg,
      {}, // No LUNC sent
      "LBUN_P2P_Market"
    );

    const txOptions = {
      msgs: [initMsg],
      gasPrices: `${gasPrice}${"uluna"}`,
      gasAdjustment: 2.2,
    };
  
    if (txOptions.gasPrices == 'undefineduluna') {
      txOptions.gasPrices = '29.000uluna'
    }


    try {
      const accountInfo = await terra.auth.accountInfo(walletAddress);

      // Simulate the transaction to get estimated fees
      const simulationFeeResult = await terra.tx.estimateFee(
        [
          {
            sequenceNumber: accountInfo.getSequenceNumber(),
            publicKey: accountInfo.getPublicKey(),
          },
        ],
        txOptions
      );

      const estimatedFeeAmount = simulationFeeResult.amount;
      const estimatedGasAmount = simulationFeeResult.gas_limit;

      const fee = new Fee(estimatedGasAmount, estimatedFeeAmount);
      txOptions.fee = fee;
 
      terra.tx.create([{ address: walletAddress }], txOptions)
      .then((signMsg) => {

        //txOptions.fee = fee;

        return wallet.post(txOptions, walletAddress);
      })
      .then((extensionResult) => {
        if (extensionResult) {
          setResult(extensionResult);
          setIsResultModalOpen(true);
        }
      })
      .catch((error) => {
        console.error(error);
        const errorMessage = error.message || "An error occurred during the transaction";
        setResult(error);
        setIsResultModalOpen(true);
      });
    } catch (error) {
      console.error(error);
      const errorMessage = error.message || "An error occurred during the transaction";
      setResult(error);
      setIsResultModalOpen(true);
    }
    return undefined; // Return undefined if instantiation fails
  };
  
  const handleChangePrice = (e) => {
    const value = e.target.value.replace(/[^0-9.]+/g, '');
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    setOrderPrice(value); // Keep the value with commas for display
    const total = numericValue * (parseFloat(orderSize.replace(/,/g, '')) || 0);
    setOrderTotal(total.toFixed(3));
    setIsBuyDisabled(false);
    setIsSellDisabled(false);
  };
  
  const handleChangeAmount = (e) => {
    const value = e.target.value.replace(/[^0-9.]+/g, '');
    const numericValue = parseFloat(value.replace(/,/g, '')) || 0;
    setOrderSize(value); // Keep the value with commas for display
    const total = (parseFloat(orderPrice.replace(/,/g, '')) || 0) * numericValue;
    setOrderTotal(total.toFixed(3));
    setIsBuyDisabled(false);
    setIsSellDisabled(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the order submission here
    console.log('Order Submitted:', { orderType, price, amount });
  };

  const handleFailure = () => {
    setTimeout(() => { }, 125)
    //setResult("There was a problem. Please try again later.")
    window.location.reload()
  }

  function transformErrMessage(input) {
    // Check if "Overflow: Cannot Sub with" exists in the string
    if (input.includes("Overflow: Cannot Sub with")) {
      // Use a regular expression to extract the two numbers after "Cannot Sub with"
      const regex = /Cannot Sub with (\d+) and (\d+)/;
      const match = input.match(regex);
  
      if (match) {
        // Convert the numbers to millions by dividing by 1000000
        const available = (parseInt(match[1]) / 1000000).toFixed(6);
        const needed = (parseInt(match[2]) / 1000000).toFixed(6);
  
        // Return the new string
        return `Needed ${needed}, but only ${available} available`;
      }
    }
  
    // Return the original string if no match
    return input;
  }

  function Dropdown({ selectedOption, handleOptionChange }) {
    return (
      <div className="dropdown-container">
        <select id="market-dropdown" value={selectedOption} onChange={handleOptionChange}>
          {Object.keys(PAIRS).map((key) => (
            <option key={key} value={key}>
              {PAIRS[key].market}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <Wrapper>
      <Peer2PeerDisclaimer ref={peer2PeerDisclaimerRef} onAgree={handleAgreement} />
      {isResultModalOpen && (
        <div className="modal-overlay">
          <Container sm>
            <Result
              response={result}
              error={
                (result instanceof Error && result) || result === "User Denied"
                  ? result
                  : undefined
              }
              parserKey={"default"}
              onFailure={handleFailure}
            />
          </Container>
        </div>
      )}
      <div className="flex flex-col items-center justify-center p-4 bg-gray-200">
        {isOwnerEntryUndefined && (
          <div className="buy-and-sell-button-wrapper">
            <button
              onClick={instantiateNewContract}
              className="instantiate-button button-valid"
            >
              Instantiate New Contract
            </button>
          </div>
        )}
        {!isOwnerEntryUndefined && (
          <div className="buy-and-sell-button-wrapper">
            <a
              href="https://luncexchange.netlify.app/?type=swap"
              className="instantiate-button button-valid"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get cwLUNC
            </a>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center justify-center p-4 bg-gray-200">
        <main className="main-wrapper">
          <div className="container">
            <div className="flipcard top-bar-item">
            <div className="left">
                <div className="pair-wrapper">
                  <img src={bIcon} alt={`${bSymbol} icon`} />
                  <div id="pair" className="user-input-entered">
                    {bSymbol}
                  </div>
                </div>
                <div className="select-market-wrapper">
                  <div id="select-a-market" className="top-bar-description">
                    Market:
                  </div>
                    <Dropdown
                      selectedOption={selectedOption}
                      handleOptionChange={handleOptionChange}
                    />
                  {/*<img src="./images/bx-chevron-down.svg" alt="dropdown button" />*/}
                </div>
              </div>
              <div className="right">
                <div className="top-bar-item">
                  <div className="top-bar-description">Price (USD)</div>
                  <div id="block-number-value" className="top-bar-value">
                    $
                    {tokenChainPrice * luncPrice >= 0.001
                      ? (tokenChainPrice * luncPrice).toFixed(3)
                      : (tokenChainPrice * luncPrice).toFixed(4)}
                  </div>
                </div>
                <div className="top-bar-item">
                  <div className="top-bar-description">Spread</div>
                    <div id="price-oracle-value" className="top-bar-value">
                      {spreadPercentage}%
                    </div> 
                </div>
              </div>
            </div>
  
            {/* Swap the order of main-item-left and main-item-right */}
            {/* Now, main-item-right comes first, followed by main-item-left */}
  
            <div className="flipcard main-item-right">
              <p className="title-selected">Orderbook</p>
              <div className="orderbook-wrapper">
                <div className="header header-cell-vertical">
                  <div className="one">Limit Price (cwLUNC)</div>
                  <div className="two">Size ({bSymbol})</div>
                </div>
                <div id="ob-table" className="main">
                  {/* Render the sell orders */}
                  <div className="sell-ob sell-price">
                    {sellOrdersList.map((data, index) => (
                      <div
                        key={`ask-${index}`}
                        className="row"
                        onClick={() => handleOrderBookClick(data, "ask")}
                      >
                        <div className="price">{data.price.toFixed(6)}</div>
                        <div className="size order-size" style={{ color: '#ff5353' }}>{data.size.toFixed(6)}</div>
                      </div>
                    ))}
                  </div>
  
                  <div className="mid-bar header-cell-vertical">
                    {/* Display the DEX/dApp price */}
                    <div className="one">{bSymbol !== "BASE" ? tokenChainPrice.toFixed(6) :            
                      "Buy: " + tokenChainPrice.toFixed(1) + " / Sell: " + (tokenChainPrice * 0.899).toFixed(1)}
                    </div>                   
                    <div className="two">
                      {tokenType === "xyk" || bSymbol === "FROG" ? "Terraport Price" : "dApp Price"}
                    </div>
                  </div>
                    
                  {/* Render the buy orders */}
                  <div className="buy-ob buy-price">
                    {buyOrdersList.map((data, index) => (
                      <div
                        key={`buy-${index}`}
                        className="row"
                        onClick={() => handleOrderBookClick(data, "bid")}
                      >
                        <div className="price">{data.price.toFixed(6)}</div>
                        <div className="size order-size" style={{ color: '#3fb68b' }}>{data.size.toFixed(6)}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
  
            <div className="flipcard main-item-left">
              <p className="title-selected">Limit Order</p>
              <div className="not-title-wrapper">
                <div className="top-wrapper">
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Price</p>
                      <div className="tooltip">
                        <img
                          className="question-circle-icon"
                          src="./images/circle-question-regular.svg"
                          alt="explanation icon"
                        />
                        <span className="tooltiptext">
                          The price of 1 {bSymbol}
                        </span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper">
                        <input
                          id="usd-size"
                          type="tel"
                          className="user-input-entered"
                          placeholder="0.00"
                          value={addCommasToNumber(orderPrice)}
                          onChange={handleChangePrice}
                        />
                        <p className="label-currency currency-next-to-size">
                          cwLUNC
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Size</p>
                      <div className="tooltip">
                        <img
                          className="question-circle-icon"
                          src="./images/circle-question-regular.svg"
                          alt="explanation icon"
                        />
                        <span className="tooltiptext">
                          Amount of traded Asset
                        </span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper" id="eth-input-wrapper">
                        <input
                          id="eth-size"
                          type="tel"
                          className="user-input-entered"
                          placeholder="0.000"
                          value={addCommasToNumber(orderSize)}
                          onChange={handleChangeAmount}
                        />
                        <p className="label-currency currency-next-to-size">
                          {bSymbol}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="label-wrapper">
                    <div className="label-user-input">
                      <p className="label-user-input-text">Total</p>
                      <div className="tooltip">
                        <img
                          className="question-circle-icon"
                          src="./images/circle-question-regular.svg"
                          alt="explanation icon"
                        />
                        <span className="tooltiptext">Total (w/o Fees)</span>
                      </div>
                    </div>
                    <div className="inputs">
                      <div className="input-wrapper">
                        <input
                          id="limit-price"
                          type="tel"
                          inputMode="decimal"
                          className="user-input-entered"
                          placeholder="0.00"
                          value={addCommasToNumber(orderTotal)}
                          readOnly
                        />
                        <p className="label-currency currency-next-to-size">
                          cwLUNC
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-wrapper">
                  <div className="fee-table">
                    <div className="fee-row-wrapper">
                      <div className="header-cell-vertical fee-row-header">
                        Fee
                      </div>
                      <div className="value-cell-vertical fee-row-value">
                        {Number(orderFee).toFixed(1)} BASE
                      </div>
                    </div>
                    <div className="fee-row-wrapper">
                      <div className="header-cell-vertical fee-row-header">
                        Order Value
                      </div>
                      <div className="value-cell-vertical fee-row-value">
                        {Number(orderTotal).toFixed(6)}
                      </div>
                    </div>
                  </div>
                  <div className="buy-and-sell-button-wrapper">
                    <button
                      id="buy-button"
                      className="buy-button button-valid"
                      onClick={handleBuyClick}
                      disabled={isBuyDisabled}
                    >
                      Buy {bSymbol}
                    </button>
                    <button
                      id="sell-button"
                      className="sell-button button-valid"
                      onClick={handleSellClick}
                      disabled={isSellDisabled}
                    >
                      Sell {bSymbol}
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            {/* Bottom-Main Flipcard remains unchanged */}
            <div className="flipcard bottom-main">
              <p className="title-selected">My Active Orders</p>
              <div className="active-orders-table-wrapper">
                <header className="header header-cell-vertical">
                  <div className="one">Side</div>
                  <div className="two">Price (cwLUNC)</div>
                  <div className="three">Size ({bSymbol})</div>
                  <div className="four">Value (USD)</div>
                  <div className="five">Action</div>
                </header>
                <div id="active-orders-main">
                  {myBuyOrders.length > 0 ? (
                    myBuyOrders.map((order) => (
                      <div
                        key={order.order_id}
                        className="row value-cell-vertical"
                      >
                        <div className="buy-side one">BUY</div>
                        <div className="two">{order.price.toFixed(6)}</div>
                        <div className="three">{order.size.toFixed(6)}</div>
                        <div className="four">
                          ${ (order.total * luncPrice).toFixed(3) }
                        </div>
                        <div className="five">
                          <button
                            className="buy-price cancel-button"
                            onClick={() =>
                              handleCancelClick(order.order_id, order.contract)
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="row value-cell-vertical">
                      <div className="buy-side one">BUY</div>
                      <div className="two"></div>
                      <div className="three"></div>
                      <div className="four"></div>
                      <div className="five"></div>
                    </div>
                  )}
                  {mySellOrders.length > 0 ? (
                    mySellOrders.map((order) => (
                      <div
                        key={order.order_id}
                        className="row value-cell-vertical"
                      >
                        <div className="sell-side one">SELL</div>
                        <div className="two">{order.price.toFixed(6)}</div>
                        <div className="three">{order.size.toFixed(6)}</div>
                        <div className="four">
                          ${ (order.total * luncPrice).toFixed(3) }
                        </div>
                        <div className="five">
                          <button
                            className="sell-price cancel-button"
                            onClick={() =>
                              handleCancelClick(order.order_id, order.contract)
                            }
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="row value-cell-vertical">
                      <div className="sell-side one">SELL</div>
                      <div className="two"></div>
                      <div className="three"></div>
                      <div className="four"></div>
                      <div className="five"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Wrapper>
  );
  
}

export default OrderBook;
