import { Route, Routes, Navigate } from "react-router-dom"
import PtoP_base from "pages/Dashboard/PtoP_base"
import PtoP_frog from "pages/Dashboard/PtoP_frog"
import PtoP_lunw from "pages/Dashboard/PtoP_lunw"
import PtoP_frg from "pages/Dashboard/PtoP_frg"
import PtoP_grdx from "pages/Dashboard/PtoP_grdx"
import PtoP_food from "pages/Dashboard/PtoP_food"
import PtoP_lnc from "pages/Dashboard/PtoP_lnc"
import PtoP_trit from "pages/Dashboard/PtoP_trit"
import PtoP_terra from "pages/Dashboard/PtoP_terra"
import PtoP_dth from "pages/Dashboard/PtoP_dth"
import PtoP_rakoff from "pages/Dashboard/PtoP_rakoff"
import PtoP_catwifhat from "pages/Dashboard/PtoP_catwifhat"
import PtoP_orderbook from "pages/Dashboard/orderbook"




export default () => (
  <Routes>
    <Route path="/orderbook" element={<PtoP_orderbook/>} />
    <Route path="*" element={<Navigate to="/orderbook" replace />} />
  </Routes>
)
